/* Waleed Css */
.table-top-head .page-btn .btn{
  height: 38px;
  /* width: 38px; */
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #e8ebed;
  background: #ffffff;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
}
.table-top-head .btn-secondary svg {
  width: 16px;
  margin-right: 5px;
  color: #ffffff;
}
.table-top-head svg.feather {
  height: 24px;
}
.search-set .search-input .feather-search.btn-searchset{
  top: 3px;
}
.btn-addproduct .btn.disabled, .btn-addproduct .btn:disabled, .btn-addproduct fieldset:disabled .btn{
  background-color: #3c5eab;
  border-color: #3c5eab;
}
.btn-addproduct .btn-submit{
  min-width: 120px;
}
.btn-addproduct .spinner-border{
  width: 1rem;
  height: 1rem;
  --bs-spinner-border-width: 0.22em;
  margin: 0;
  top: 1px;
  position: relative;
}
.search-path .btn.btn-filter svg.feather-x{
  color: #ffffff !important;
  width: 22px;
  height: 22px;
}
#filter_inputNew{
  border: 0;
  margin: 0;
}
#filter_inputNew .card-body{
  padding: 0 24px 24px 24px;
}
.variantDeletBtn .remove-color{
  background: transparent;
  padding: 0;
  border: 0;
  box-shadow: none;
  color: #5B6670;
}
.variantDeletBtn .remove-color svg{
  width: 16px;
  height: 16px;
}
.variantDeletBtn .remove-color:hover{
  --bs-text-opacity: 1;
  opacity: 1;
  color: #3c5eab;
}
.card.cardBodyLoader{
  background-color: transparent;
}
.cardBodyLoader .card-body{
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cardBodyLoader .card-body .text-light{
  color: #3c5eab !important;
}
.removeVariant.btn-addproduct{
  float: left;
}
.ant-switch.ant-switch-checked{
  background: #3c5eab !important;
}
.react-select__menu{
  z-index: 999 !important;
}
.deletBoxCol .input-blocks.variantDeletBtn{
  margin-bottom: 0;
  margin-right: 5px;
}
.deletBoxCol .input-blocks.variantDeletBtn button{
  position: relative;
  top: -2px;
}
.white{
  color: #ffffff !important;
}
.sales-details-items .details-item.details-itemLast p{
  font-size: 15px;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
}
.successColor{
  color: #28C76F !important;
}
.weeklyEarning{
  min-height: 146px;
}
.overLayBox li{
  list-style: none;
}
.welcome .welcome-text h6{
  position: relative;
  top: 2px;
}
.recent-transactions thead tr th,
.recent-transactions tbody tr td{
  text-align: center !important;
}
.recent-transactions thead tr th:nth-child(1),
.recent-transactions thead tr th:nth-child(2),
.recent-transactions tbody tr td:nth-child(1),
.recent-transactions tbody tr td:nth-child(2){
  text-align: left !important;
}
/* Waleed Css */